import AuthFlow from '@/components/atoms/AuthFlow'
import OutlineButton from '@/components/atoms/Button/OutlineButton'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import ErrorIcon from '@/components/atoms/Icon/svg/ErrorIcon'
import { withFooter } from '@/components/molecules/Footer'
import Account from '@/components/template/Account'
import { ERROR } from '@/constant/errors'
import { ROUTES } from '@/routes'
import theme from '@/theme'
import translate, { translations } from '@/utils/translations'
import { passwordValidate } from '@/utils/validate'
import { Auth } from '@aws-amplify/auth'
import { makeStyles } from '@material-ui/core'
import { navigate } from 'gatsby-link'
import React, { Dispatch, FormEvent, ReactNode, SetStateAction, useEffect, useState } from 'react'

const useStyle = makeStyles({
    resetPassword: {
        minHeight: `calc(100vh - 50px - ${theme.footer.footerHeight})`,
    },
    resetPassWrapper: {
        position: 'relative',
        minHeight: 'calc(100vh - 200px)',
        paddingBottom: 150,
    },
    form: {
        width: 450,
    },
    listInstruction: {
        margin: 0,
        paddingLeft: 20,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    instructionText: {
        color: theme.colors.black,
        fontWeight: 'lighter',
        margin: 0,
        fontSize: 14,
    },
    listError: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
    },
    errorText: {
        marginTop: 10,
        color: theme.colors.errorN,
        listStyle: 'none',
        fontWeight: 600,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
    },
    primaryBtn: {
        marginTop: 20,
    },
    outlineBtn: {
        marginTop: 5,
    },
    passwordResetOKText: {
        padding: '60px 0 40px',
        width: '450px',
        textAlign: 'center',
    },
})

const ResetPasswordRequestForm = (props: { setForm: Dispatch<SetStateAction<ReactNode>> }) => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const classes = useStyle()
    async function forgotPassword(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const form = e.currentTarget
        const elements = form.elements as typeof form.elements & {
            username: HTMLInputElement
        }
        setLoading(true)
        try {
            await Auth.forgotPassword(elements.username.value)
            setLoading(false)
            props.setForm(<ResetPasswordForm username={elements.username.value} setForm={props.setForm} />)
        } catch (err: any) {
            const error = translate(err?.message)
            setError(error || ERROR.ERROR_OCCURRED)
            setLoading(false)
        }
    }

    return (
        <form onSubmit={forgotPassword} className={classes.form}>
            <div>
                <p className={classes.instructionText}>
                    パスワードを再設定するメールアドレスを入力してください。
                    <br />
                    パスワード再設定用の認証コードが送信されます。
                </p>
            </div>

            <AuthFlow.Grid>
                <AuthFlow.FormField
                    id="reset-password-form-username"
                    name="username"
                    type="email"
                    placeholder="メールアドレス"
                />
            </AuthFlow.Grid>
            {error && (
                <div className={classes.errorText}>
                    <ErrorIcon />
                    &nbsp;{error}
                </div>
            )}
            <PrimaryButton type="submit" fullSize className={classes.primaryBtn} disabled={loading}>
                メールを送信する
            </PrimaryButton>
            <OutlineButton fullSize className={classes.outlineBtn} onClick={() => navigate(ROUTES.LOGIN)}>
                戻る
            </OutlineButton>
        </form>
    )
}

const ResetPasswordForm = (props: { username: string; setForm: Dispatch<SetStateAction<ReactNode>> }) => {
    const [errors, setErrors] = useState<Array<string>>([])
    const [loading, setLoading] = useState(false)
    const classes = useStyle()

    const forgotPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const form = e.currentTarget
        const elements = form.elements as typeof form.elements & {
            code: HTMLInputElement
            newPassword: HTMLInputElement
        }
        const errors = passwordValidate(props.username, elements.newPassword.value)
        if (errors.length) {
            setErrors(errors)
            return
        }

        setLoading(true)
        // @ts-ignore
        try {
            await Auth.forgotPasswordSubmit(props.username, elements.code.value, elements.newPassword.value)
            setLoading(false)
            props.setForm(<PasswordResetOKForm />)
        } catch (err: any) {
            setLoading(false)
            const translation = translate(err.message)
            if (translation) setErrors([...errors, translation])
            else setErrors([...errors, translations['ja']['Invalid verification code provided, please try again.']])
        }
    }

    return (
        <>
            <form onSubmit={forgotPasswordSubmit} className={classes.form}>
                <AuthFlow.FlexCenter>
                    <div>
                        <h4 className={classes.instructionText}>以下の条件を満たすパスワードを設定してください。</h4>
                        <ul className={classes.listInstruction}>
                            <li className={classes.instructionText}>
                                半角の英大文字、英小文字、数字をそれぞれ1文字以上含む
                            </li>
                            <li className={classes.instructionText}>12文字以上</li>
                            <li className={classes.instructionText}>メールアドレスと異なる</li>
                        </ul>
                    </div>
                </AuthFlow.FlexCenter>
                <AuthFlow.Grid>
                    <AuthFlow.PasswordField
                        id="reset-form-password"
                        name="newPassword"
                        placeholder="新しいパスワード"
                    />

                    <AuthFlow.FormField id="reset-password-form-code" name="code" placeholder="認証コード" />
                </AuthFlow.Grid>
                <div>
                    <ul className={classes.listError}>
                        {Boolean(errors.length) &&
                            errors.map((error) => (
                                <li className={classes.errorText} key={error}>
                                    <ErrorIcon />
                                    &nbsp;
                                    {error}
                                </li>
                            ))}
                    </ul>
                </div>

                <PrimaryButton type="submit" fullSize className={classes.primaryBtn} disabled={loading}>
                    変更する
                </PrimaryButton>
                <OutlineButton fullSize className={classes.outlineBtn} onClick={() => navigate(ROUTES.LOGIN)}>
                    戻る
                </OutlineButton>
            </form>
        </>
    )
}

const PasswordResetOKForm = () => {
    const classes = useStyle()
    return (
        <div>
            <div className={classes.passwordResetOKText}>パスワードを変更しました</div>
            <PrimaryButton fullSize className={classes.primaryBtn} onClick={() => navigate(ROUTES.LOGIN)}>
                変更する
            </PrimaryButton>
        </div>
    )
}

function ResetPasswordInner() {
    const [form, setForm] = useState<ReactNode>()
    const classes = useStyle()

    useEffect(() => {
        setForm(<ResetPasswordRequestForm setForm={setForm} />)
    }, [])

    return (
        <div className={classes.resetPassword}>
            <Account subtitile="パスワード再設定">{form && form}</Account>
        </div>
    )
}

const ResetPassword = () => (
    <>
        <title>パスワード再設定｜ScopeX</title>
        <ResetPasswordInner />
    </>
)
export default withFooter(ResetPassword)
